.imageContainer {
    position: relative;
    width: 100%;
  }
  
  .backgroundImage {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    /* background-repeat: no-repeat;
    background-size: cover; */
    display: block;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.596); /* Semi-transparent overlay */
    color: white;
    display: flex;
    flex-direction: column;
    /* align-items: center;
    justify-content: center; */
    text-align: center;
    padding: 20px;
    /* box-sizing: border-box; */
  }


  .stickyContainer {
    height: 300px; /* Set the desired height of the scrolling container */
    overflow-y: scroll; /* Enable vertical scrolling */
    border: 1px solid #ccc; /* Just for visual reference */
    position: relative;
  }

  .stickyImage {
    position: sticky;
    top: 10px; /* Adjust the top offset as needed */
    width: 100px; /* Set the desired width of the image */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* .overlay h2,
  .overlay p,
  .overlay button {
    margin: 10px 0;
  } */