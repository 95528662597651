.multilineTruncation {
    width: 300px; /* Set the desired width */
    display: -webkit-box; /* Required for webkit-based browsers */
    -webkit-box-orient: vertical; /* Required for webkit-based browsers */
    -webkit-line-clamp: 5; /* Number of lines to display before truncating */
    overflow: hidden; /* Ensures that any overflowed text is hidden */
    text-overflow: ellipsis; /* Adds an ellipsis (...) at the end of the text */
    padding: 5px; /* Optional: Adds some padding */
  }

  .multilineTruncation {
    width: 300px; /* Set the desired width */
    display: -webkit-box; /* Required for webkit-based browsers */
    -webkit-box-orient: vertical; /* Required for webkit-based browsers */
    -webkit-line-clamp: 5; /* Number of lines to display before truncating */
    overflow: hidden; /* Ensures that any overflowed text is hidden */
    text-overflow: ellipsis; /* Adds an ellipsis (...) at the end of the text */
    padding: 5px; /* Optional: Adds some padding */
  }